import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch,useSelector } from "react-redux";

import { AppState } from "../store/definitions";
import { changeExpandedStatus } from "../store/actions/genericActions";
import { containerStyles, titleStyles } from "./styles/Header.styles";

export const Header = ({ customTitle }: { customTitle?: string }) => {
    const expanded = useSelector((state: AppState) => state.session.expanded);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(); // Destructure the 't' function and 'i18n' object from useTranslation
    console.log(i18n.language);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        handleClose();
    };

    return (
        <>
            <Box as="header" {...containerStyles}>
                <Text as="h2" {...titleStyles}>
                    {customTitle || t("liveChat")}
                </Text>
                <div>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={open ? "demo-positioned-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        style={{ color: "#fff" }}
                    >
                        {i18n.language}
                    </Button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                    >
                        <MenuItem onClick={() => changeLanguage("th")}>th</MenuItem>
                        <MenuItem onClick={() => changeLanguage("en")}>en</MenuItem>
                    </Menu>
                    <CloseIcon style={{ color: "#fff", paddingRight: "3px" , cursor : 'pointer' }} onClick={() => dispatch(changeExpandedStatus({ expanded: !expanded }))} 
                    />
                </div>
            </Box>
        </>
    );
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                liveChat: "Live chat",
                headerContext: "Hi there!",
                describetion: "We're here to help. Please give us some info to get started.",
                name: "Name",
                nameInputBoxText: "Please enter your name",
                email: "Email address",
                emailinputBoxText: "Please enter your email address",
                question: "How can we help you?",
                questionInputboxText: " Ask a question",
                startChat: "start chat !",
                Concierge: "Concierge",
                welcomeMessage1: "Welcome ",
                welcomeMessage2 : '! An agent will be with you in just a moment.',
                chatStarted: "Chat started",
                messageBoxText: "Type your message",
            }
        },
        th: {
            translation: {
                liveChat: "Live chat",
                headerContext: "สวัสดี!",
                describetion: "เราอยู่ที่นี่เพื่อช่วยคุณ โปรดให้ข้อมูลเพื่อเริ่มต้นการสนทนา",
                name: "ชื่อ",
                nameInputBoxText: "โปรดระบุชื่อของคุณ",
                email: "อีเมล์",
                emailinputBoxText: "โปรดระบุอีเมล์ของคุณ",
                question: "เราจะช่วยคุณได้อย่างไร?",
                questionInputboxText: "โปรดระบุปัญหาของคุณ",
                startChat: "เริ่มต้นการสนทนา !",
                Concierge: "เจ้าหน้าที่",
                welcomeMessage: "ยินดีต้อนเรา! เจ้าหน้าที่จะมาถึงในไม่ช้า",
                chatStarted: "เวลาเริ่มการสนทนา",
                messageBoxText: "ข้อความ",
            }
        }
    },
    lng: "th", // Set the default language
    fallbackLng: "en",

    interpolation: {
        escapeValue: false
    }
});

export default i18n;
